<template>
  <!-- introduce -->
  <div id="introduce">
    <div class="mainVisual">
      <h2 class="catchP">
        <!-- <div class="depth0">UNBELIEVABLE<br> SMART STORE</div> -->
        <img src="~@/assets/img/introduce/img_main_visual_logo.png" alt="" class="mainVisualLogo">
      </h2>
      <img src="~@/assets/img/introduce/img_main_visual.png" alt="" class="mainVisualImg">

      <div class="movieYoutube" @click="playYoutube">
        <!-- <iframe width="100%" height="100%" src="https://www.youtube.com/embed/NBLPufNFV9o?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        <youtube :video-id="videoId" ref="youtube"></youtube>
      </div>
    </div>

    <div class="contentBox">
      <div class="content1">
        <h3>놀라바 소개</h3>

        <div class="titleSt01">
          <strong class="contentTitle">
            상권 맞춤 무인편의점<br><span>놀라바!</span>
          </strong>
        </div>

        <div class="listCont1">
          <ul>
            <li><img src="~@/assets/img/introduce/img_thumb_0101.png" alt="" /></li>
            <li><img src="~@/assets/img/introduce/img_thumb_0102.png" alt="" /></li>
            <li><img src="~@/assets/img/introduce/img_thumb_0103.png" alt="" /></li>
            <li class="textBanner">
              <img src="~@/assets/img/introduce/img_thumb_0104.png" alt="" />
              <div><p>맞춤 정장, 맞춤 구두, 맞춤 가구의<br>공통점은?</p></div>
            </li>
          </ul>

          <p>나에게 딱 맞는 나만의 맞춤 상품!</p>
        </div>

        <div class="listCont2">
          <ul>
            <li><img src="~@/assets/img/introduce/img_thumb_0201.png" alt="" /></li>
            <li><img src="~@/assets/img/introduce/img_thumb_0202.png" alt="" /></li>
            <li><img src="~@/assets/img/introduce/img_thumb_0203.png" alt="" /></li>
            <li><img src="~@/assets/img/introduce/img_thumb_0204.png" alt="" /></li>
          </ul>

          <div class="textBanner">
            <img src="~@/assets/img/introduce/img_thumb_0205.png" alt="" />
            <div>
              <p>
                <span>체계적인 상권 분석</span>
                <em>
                  <span>상품</span>
                  <span>매장구성</span>
                  <span>인테리어</span>
                </em>
              </p>
            </div>
          </div>
          
          <div class="textBox">
            <p>상권에 <span>딱!</span> 맞는 <br>나만의 맞춤 매장 오픈!!</p>
          </div>
        </div>
      </div>

      <div class="content2">
        <div class="titleSt01">
          <strong class="contentTitle">
            24시간 무인편의점과<br>
            <em>인기 캐릭터 라바와의<br>놀라운 만남</em>
            <span>놀라바!</span>
          </strong>
          <p class="contentExplain">
            놀라바는 애니메이션 캐릭터 라바를 모티브로 한<br>
            24시간 무인편의점의 이름입니다.
            <span>유튜브 44억 조회수와 983만 구독자를 자랑하는<br>
            세계적인 콘텐츠 라바와 함께 하실<br> 파트너를 모십니다!</span>
          </p>
        </div>

        <ul>
          <li>
            <img src="~@/assets/img/introduce/img_netflix.png" alt="">
            <p>
              실 시청자수 6억 800만 명 넷플릭스에<br>
              <span>오리지널 콘텐츠 방영</span>
            </p>
          </li>
          <li>
            <img src="~@/assets/img/introduce/img_youtube.png" alt="">
            <p>
              유튜브 44억 조회수와 983만 구독자,<br>
              <span>매일 270만 뷰로 전세계인들과</span>
              소통하고 있는 라바 콘텐츠
            </p>
          </li>
          <li>
            <img src="~@/assets/img/introduce/img_facebook.png" alt="">
            <p>
              210만 진성 팔로워를 통한<br>
              6억 3천명의 홍보 규모
            </p>
          </li>
          <li class="appStore">               
            <img src="~@/assets/img/introduce/img_store.png" alt="">
            <p>
              모바일 게임 4종 3천 6백만 다운로드로<br>
              매월 259만 명 라바 게임 플레이
            </p>
          </li>
        </ul>
      </div>

      <div class="content3">
        <div class="titleSt01">
          <strong class="contentTitle">
            무인점포 창업을 <br>고민중이신가요?
            <em>무인 편의점은 다 똑같다고 <br>생각하시나요? </em>
            <span>그렇다면 지금이 바로 <br>놀라바를 시작할 때 입니다!</span>
          </strong>
        </div>

        <div class="listCont">
          <span>놀라바! 이런 분들에게 추천합니다!</span>
          <ul>
            <li>인건비가 부담되어 운영이 어려운<br><strong>기존 편의점 점주님</strong></li>
            <li>투잡으로 수익 추가를 원하는<br><strong>예비 창업자님</strong></li>
            <li>상권분석 부족으로 입지선택에<br>어려움을 겪고 있는 <strong>예비 창업자님</strong></li>
            <li>기존 무인매장 운영중으로<br>스텝업이 필요한 <strong>기존 무인매장 점주님</strong></li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)

export default {
  data(){
    return{
      videoId: 'E6LpIdtXmRg',
      playing: false,
    }
  },
  computed:{
    player(){
      return this.$refs.youtube.player
    }
  },
  methods: {
    playYoutube(e){
      console.log(e.target)
      e.target.classList.add('active');

      this.playVideo();
    },
    playVideo(){
        this.player.playVideo();
        this.playing = true;
    },
  }
}
</script>