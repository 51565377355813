var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"brand"}},[_vm._m(0),_c('div',{staticClass:"contentBox"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"content4"},[_vm._m(4),_vm._m(5),_c('p',[_c('span',[_vm._v(" DID에 대한 더 자세한 설명은 "),_c('strong',{on:{"click":function($event){return _vm.openWindow('https://blog.naver.com/tubanretail/222893417262')}}},[_vm._v("Click!")]),_vm._v(" 해 보세요! ")])])]),_vm._m(6),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainVisual"},[_c('h2',[_vm._v(" 24시간 무인편의점 놀라바 "),_c('strong',[_vm._v("브랜드 경쟁력")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content1"},[_c('h3',[_vm._v("브랜드 경쟁력")]),_c('div',{staticClass:"titleSt01"},[_c('span',[_vm._v("1")]),_c('strong',{staticClass:"contentTitle"},[_c('em',[_vm._v("체계적인 상권 분석에 따른 점포개발, "),_c('br')]),_vm._v(" 상권에 따라 타겟 고객에 맞는 "),_c('br'),_c('span',[_vm._v("상품, 매장구성, 인테리어 등 제안!")])])]),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0101.png"),"alt":""}}),_c('div',[_c('p',[_c('span',[_vm._v("어린이, 학생 유동이 많은 아파트단지")]),_c('strong',[_vm._v("무인 (문구/아이스크림) 편의점!")])])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0102.png"),"alt":""}}),_c('div',[_c('p',[_c('span',[_vm._v("1인가구, 주택가 밀집 지역 등은")]),_c('strong',[_vm._v("무인 (밀키트) 편의점!")])])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0103.png"),"alt":""}}),_c('div',[_c('p',[_c('span',[_vm._v("직장인, 오피스 밀집 상권은")]),_c('strong',[_vm._v("무인 (카페) 편의점!")])])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0104.png"),"alt":""}}),_c('div',[_c('p',[_vm._v("이외 다양한 상권, 지역에 따라"),_c('br'),_vm._v("딱 맞춰 다양하게 오픈 가능!")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content2"},[_c('div',{staticClass:"titleSt01"},[_c('span',[_vm._v("2")]),_c('strong',{staticClass:"contentTitle"},[_c('em',[_vm._v("세계적인 인기 캐릭터"),_c('br'),_vm._v("라바를 활용한"),_c('br')]),_c('span',[_vm._v("차별화된 인테리어&굿즈 판매")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_larvar.png"),"alt":""}}),_c('p',[_vm._v("'라바'는 전 세계가 사랑하는 국내 토종"),_c('br'),_vm._v(" 캐릭터입니다.")]),_c('p',[_vm._v("라바 캐릭터 굿즈 뿐만 아니라 투바앤의 다양한 캐릭터 굿즈 판매로 매출 향상에 도움을 드립니다.")])])]),_c('div',{staticClass:"interior"},[_c('div',[_c('span',[_vm._v("놀라바 "),_c('strong',[_vm._v("인테리어")])]),_c('p',[_vm._v("라바 캐릭터를 활용하여"),_c('br'),_vm._v("입지와 상권에 맞는 모던하고 유니크한 인테리어를 연출합니다.")])]),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0201.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0202.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0203.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0204.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content3"},[_c('div',{staticClass:"titleSt01"},[_c('span',[_vm._v("3")]),_c('strong',{staticClass:"contentTitle"},[_c('em',[_vm._v("다양하고 독특한 매대 구성으로 ")]),_c('span',[_vm._v("상품 경쟁력 강화")])]),_c('p',{staticClass:"contentExplain"},[_vm._v(" 스페셜존 구성으로 경쟁사 대비 "),_c('br'),_vm._v("독특한 매대를 제공해 드립니다. ")])]),_c('ul',[_c('li',[_c('div',[_c('span',[_vm._v("라바굿즈")])]),_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0301.png"),"alt":""}})]),_c('li',[_c('div',[_c('span',[_vm._v("놀방구")])]),_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0302.png"),"alt":""}})]),_c('li',[_c('div',[_c('span',[_vm._v("핵인싸템")])]),_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0303.png"),"alt":""}})]),_c('li',[_c('div',[_c('span',[_vm._v("비건&"),_c('br'),_vm._v("다이어트존 등")])]),_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0304.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleSt01"},[_c('span',[_vm._v("4")]),_c('strong',{staticClass:"contentTitle"},[_c('em',[_vm._v("디지털 사이니지를 활용한"),_c('br'),_vm._v("상품소개, 프로모션 안내로")]),_c('span',[_vm._v("고객과 소통합니다.")])]),_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0401.gif"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',[_vm._v("매장의 대형 모니터인 디지털사이니지를 통해"),_c('br'),_vm._v("놀라바는 언제나 고객과 소통합니다.")])]),_c('li',[_c('span',[_vm._v("상품소개 / 프로모션 / 정보안내 등"),_c('br'),_vm._v("다양한 컨텐츠로 고객의 매장 유입을 유도합니다.")])]),_c('li',[_c('span',[_vm._v("컨텐츠는 본사에서 무상으로 제작하여"),_c('br'),_vm._v("제공 해 드립니다.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content5"},[_c('div',{staticClass:"titleSt01"},[_c('span',[_vm._v("5")]),_c('strong',{staticClass:"contentTitle"},[_c('em',[_vm._v("매장 운영에 대한")]),_c('span',[_vm._v("자의성을 드립니다.")])])]),_c('ul',[_c('li',[_vm._v("계약기간 2년")]),_c('li',[_vm._v("해지 위약금 free")]),_c('li',[_vm._v("상품운영, 가격 자율성 부여")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content6"},[_c('div',{staticClass:"titleSt01"},[_c('span',[_vm._v("6")]),_c('strong',{staticClass:"contentTitle"},[_c('em',[_vm._v("놀라바")]),_c('span',[_vm._v("보안시스템")])]),_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0601.png"),"alt":""}})]),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0605.png"),"alt":""}}),_c('div',{staticClass:"inner"},[_c('div',[_c('em',[_vm._v("POINT 1")]),_c('p',[_vm._v("24시간 영상 감시 솔루션 채택으로"),_c('br'),_vm._v("도난, 화재상황 발생 시"),_c('br'),_vm._v("초기 신속 대응으로 피해 최소화")]),_c('span',[_vm._v("도난침입 및 연기감지를 통한 화재 상황 발생 시 초기 신속 대응 가능")])])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0602.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0605.png"),"alt":""}}),_c('div',{staticClass:"inner"},[_c('div',[_c('em',[_vm._v("POINT 2")]),_c('p',[_vm._v("도난 / 파손사고 발생 시 녹화 여부에 따라"),_c('br'),_vm._v("50% ~ 100% 손실 보상 보험")]),_c('span',[_vm._v("(가입상품에 따라 한도는 변경되며, 월 비용 발생)")])])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0603.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0605.png"),"alt":""}}),_c('div',{staticClass:"inner"},[_c('div',[_c('em',[_vm._v("POINT 3")]),_c('p',[_vm._v("전용 어플리케이션으로"),_c('br'),_vm._v("손쉽게 관제!")])])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/img/brand/img_thumb_0604.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }