<template>
  <ul class="snsLinks">
    <!-- <li @click="openWindow(`https://m.facebook.com/%EB%86%80%EB%9D%BC%EB%B0%94-100773915823716/`)">
      <img src="../assets/img/common/facebook_icon.png" alt="페이스북" class="facebookIcon">
    </li>
    <li @click="openWindow(`https://blog.naver.com/tubanretail`)">
      <img src="../assets/img/common/naver_blog_icon.png" alt="네이버블로그" class="naverBlogIcon" >
    </li> -->
    <li  @click="moveToPage(6)">
      <img src="../assets/img/common/contact_icon.png" alt="가맹점주님 공간" class="" >
    </li>
  </ul>
</template>

<script>
export default {
  props:['gnbMenu'],
  methods: {
    openWindow(url) {
      window.open(url, "_blank")
    },
    alertStandby() {
      alert('준비중입니다.')
    },
    moveToPage(value){
      // this.$router.push({query: {page_index: value.toString()}})

      this.$emit('changePage', value)
    },
  },
}
</script>