<template>
  <div id="brand">
  <div class="mainVisual">
    <h2>
      24시간 무인편의점 놀라바
      <strong>브랜드 경쟁력</strong>
    </h2>
  </div>
  <div class="contentBox">
    <div class="content1">
      <h3>브랜드 경쟁력</h3>

      <div class="titleSt01">
        <span>1</span>
        <strong class="contentTitle">
          <em>체계적인 상권 분석에 따른 점포개발, <br></em>
          상권에 따라 타겟 고객에 맞는 <br>
          <span>상품, 매장구성, 인테리어 등 제안!</span>
        </strong>
      </div>

      <ul>
        <li><img src="~@/assets/img/brand/img_thumb_0101.png" alt="" />
          <div>
            <p>
              <span>어린이, 학생 유동이 많은 아파트단지</span>
              <strong>무인 (문구/아이스크림) 편의점!</strong>
            </p>
          </div>
        </li>
        <li><img src="~@/assets/img/brand/img_thumb_0102.png" alt="" />
          <div>
            <p>
              <span>1인가구, 주택가 밀집 지역 등은</span>
              <strong>무인 (밀키트) 편의점!</strong>
            </p>
          </div>
        </li>
        <li><img src="~@/assets/img/brand/img_thumb_0103.png" alt="" />
          <div>
            <p>
              <span>직장인, 오피스 밀집 상권은</span>
              <strong>무인 (카페) 편의점!</strong>
            </p>
          </div>
        </li>
        <li><img src="~@/assets/img/brand/img_thumb_0104.png" alt="" />
          <div>
            <p>이외 다양한 상권, 지역에 따라<br>딱 맞춰 다양하게 오픈 가능!</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="content2">
      <div class="titleSt01">
        <span>2</span>
        <strong class="contentTitle">
          <em>세계적인 인기 캐릭터<br>라바를 활용한<br></em>
          <span>차별화된 인테리어&amp;굿즈 판매</span>
        </strong>

        <div>
          <img src="~@/assets/img/brand/img_larvar.png" alt="" />
          <p>'라바'는 전 세계가 사랑하는 국내 토종<br> 캐릭터입니다.</p>
          <p>라바 캐릭터 굿즈 뿐만 아니라 투바앤의 다양한 캐릭터 굿즈 판매로 매출 향상에 도움을 드립니다.</p>
        </div>
      </div>

      <div class="interior">
        <div>
          <span>놀라바 <strong>인테리어</strong></span>
          <p>라바 캐릭터를 활용하여<br>입지와 상권에 맞는 모던하고 유니크한 인테리어를 연출합니다.</p>
        </div>

        <ul>
          <li><img src="~@/assets/img/brand/img_thumb_0201.png" alt="" /></li>
          <li><img src="~@/assets/img/brand/img_thumb_0202.png" alt="" /></li>
          <li><img src="~@/assets/img/brand/img_thumb_0203.png" alt="" /></li>
          <li><img src="~@/assets/img/brand/img_thumb_0204.png" alt="" /></li>
        </ul>
      </div>
    </div>

    <div class="content3">
      <div class="titleSt01">
        <span>3</span>
        <strong class="contentTitle">
          <em>다양하고 독특한 매대 구성으로 </em>
          <span>상품 경쟁력 강화</span>
        </strong>

        <p class="contentExplain">
          스페셜존 구성으로 경쟁사 대비 <br>독특한 매대를 제공해 드립니다.
        </p>
      </div>

      <ul>
        <li>
          <div><span>라바굿즈</span></div>
          <img src="~@/assets/img/brand/img_thumb_0301.png" alt="" />
        </li>
        <li>
          <div><span>놀방구</span></div>
          <img src="~@/assets/img/brand/img_thumb_0302.png" alt="" />
        </li>
        <li>
          <div><span>핵인싸템</span></div>
          <img src="~@/assets/img/brand/img_thumb_0303.png" alt="" />
        </li>
        <li>
          <div><span>비건&amp;<br>다이어트존 등</span></div>
          <img src="~@/assets/img/brand/img_thumb_0304.png" alt="" />
        </li>
      </ul>
    </div>

    <div class="content4">
      <div class="titleSt01">
        <span>4</span>
        <strong class="contentTitle">
          <em>디지털 사이니지를 활용한<br>상품소개, 프로모션 안내로</em>
          <span>고객과 소통합니다.</span>
        </strong>

        <img src="~@/assets/img/brand/img_thumb_0401.gif" alt="" />
      </div>

      <ul>
        <li><span>매장의 대형 모니터인 디지털사이니지를 통해<br>놀라바는 언제나 고객과 소통합니다.</span></li>
        <li><span>상품소개 / 프로모션 / 정보안내 등<br>다양한 컨텐츠로 고객의 매장 유입을 유도합니다.</span></li>
        <li><span>컨텐츠는 본사에서 무상으로 제작하여<br>제공 해 드립니다.</span></li>
      </ul>

      <p>
        <span>
          DID에 대한 더 자세한 설명은 
          <strong @click="openWindow('https://blog.naver.com/tubanretail/222893417262')">Click!</strong> 
          해 보세요!
        </span>
      </p>
    </div>

    <div class="content5">
      <div class="titleSt01">
        <span>5</span>
        <strong class="contentTitle">
          <em>매장 운영에 대한</em>
          <span>자의성을 드립니다.</span>
        </strong>
      </div>

      <ul>
        <li>계약기간 2년</li>
        <li>해지 위약금 free</li>
        <li>상품운영, 가격 자율성 부여</li>
      </ul>
    </div>

    <div class="content6">
      <div class="titleSt01">
        <span>6</span>
        <strong class="contentTitle">
          <em>놀라바</em>
          <span>보안시스템</span>
        </strong>
        <img src="~@/assets/img/brand/img_thumb_0601.png" alt="" />
      </div>

      <ul>
        <li><img src="~@/assets/img/brand/img_thumb_0605.png" alt="" />
          <div class="inner">
            <div>
              <em>POINT 1</em>
              <p>24시간 영상 감시 솔루션 채택으로<br>도난, 화재상황 발생 시<br>초기 신속 대응으로 피해 최소화</p>
              <span>도난침입 및 연기감지를 통한 화재 상황 발생 시 초기 신속 대응 가능</span>
            </div>
          </div>
        </li>
        <li><img src="~@/assets/img/brand/img_thumb_0602.png" alt="" /></li>
        <li><img src="~@/assets/img/brand/img_thumb_0605.png" alt="" />
          <div class="inner">
            <div>
              <em>POINT 2</em>
              <p>도난 / 파손사고 발생 시 녹화 여부에 따라<br>50% ~ 100% 손실 보상 보험</p>
              <span>(가입상품에 따라 한도는 변경되며, 월 비용 발생)</span>
            </div>
          </div>
        </li>
        <li><img src="~@/assets/img/brand/img_thumb_0603.png" alt="" /></li>
        <li><img src="~@/assets/img/brand/img_thumb_0605.png" alt="" />
          <div class="inner">
            <div>
              <em>POINT 3</em>
              <p>전용 어플리케이션으로<br>손쉽게 관제!</p>
            </div>
          </div>
        </li>
        <li><img src="~@/assets/img/brand/img_thumb_0604.png" alt="" /></li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
  methods: {
    openWindow (url) {
      window.open(url, "_blank")
    }
  },
}
</script>