<template>
    <div id="contact">
        <div class="inner">
          <div>
            <div class="info">
              <h2>24시간 무인편의점 놀라바</h2>
              <ul>
                <li>
                  <span>가맹사업문의.</span>
                  <span><strong>1544-0510</strong></span>
                </li>
                <li>
                  <span>주소</span>
                  <span>서울특별시 종로구 경희궁 1길 1  투바앤빌딩<br>(우. 03176)</span>
                </li>
              </ul>
            </div>

            <naver-maps class="naverMaps" :domId="`mainMap`" :width="1270" :height="478" :mapOptions="mapOptions">
              <naver-marker :lat="37.5706558427026" :lng="126.971684721382"></naver-marker>
            </naver-maps>
          </div>
        </div>
        <footer id="footer">
          <div class="footerContainer"> 
            <img src="~@/assets/img/common/img_footer_logo.png" alt="놀라바" />
            <ol>
              <li>㈜투바앤리테일 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 대표이사 <span>김낙용</span></li>
              <li>서울특별시 종로구 경희궁 1길 1 투바앤빌딩2층(우03176)</li>
              <li>사업자등록번호 373-88-02124</li>
              <li>TEL <span>1544-0510</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-MAIL <span>cs@tubanretail.com</span></li>
              <li>Copyright 2021 TubanRetail all rights reserved.</li>
            </ol>
          </div>
        </footer>
      </div>
</template>

<script>
export default {
  data() {
    return {
      mapOptions: {
        lat: 37.5706558427026,
        lng: 126.971684721382,
        zoomControl: false,
        draggable: true
      },
    }
  },
}
</script>